import React from 'react';
import Softskills from '../../components/About/Process/Softskills';
import HowWeWork from '../../components/About/Process/HowWeWork';

const Process = () => (
  <section>
    <Softskills />
    <HowWeWork />
  </section>
);

export default Process;
