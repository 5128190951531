import React from 'react';
import Fade from 'react-reveal/Fade';
import SeoTitle from '../../Title/SeoTitle';

import icon2 from '../../../images/icons/icon2.png';
import icon1 from '../../../images/icons/icon1.png';
import icon5 from '../../../images/icons/icon5.png';
import icon3 from '../../../images/icons/icon3.png';

const HowWeWorkItem = ({
  fadeDuration,
  iconPath,
  title,
  description,
}) => ((
  <Fade bottom duration={fadeDuration}>
    <div className="col-lg-6">
      <div className="row d-flex align-items-center" >
        <img
          src={iconPath}
          alt=""
          style={{
            height: '3rem',
            width: '3rem',
            padding: '.7rem',
            objectFit: 'contain',
          }}
        />
        <h4>{title}</h4>
      </div>
      <p>{description}</p>
    </div>
  </Fade>
));

const HowWeWork = () => (
  <section className="seo_service_area sec_pad">
    <SeoTitle
      Title="How does it work?"
    />
    <div className="container">
      <div className="row">
        <HowWeWorkItem
          fadeDuration={500}
          iconPath={icon2}
          title="Project Based"
          description="What’s your end goal? Our agency can get you there. You can be as hands on or off as you like. Whether it’s enhancing current software or developing something completely new, we will map out your solution, document our work, and set up your internal teams for ongoing success. We always strive to leave your code base in a state that will facilitate ongoing maintenance and user adoption as you scale."
        />
        <HowWeWorkItem
          fadeDuration={700}
          iconPath={icon3}
          title="Hourly"
          description="OPNA can go beyond the standard consultancy delivery model and reinforce your team when the going gets tough. Whether you just need more people power or outside knowledge, when our team of engineers embeds with your team we'll be more than just an extra set of hands. We’ll help guide your strategy, enact best practices to ensure a sustainable product, and help your team level up along the way."
        />
        <HowWeWorkItem
          fadeDuration={900}
          iconPath={icon1}
          title="Retainer"
          description="Have the team at OPNA on stand-by for all those unexpected but unavoidable surprises that can impact your delivery. Have us on call at a flat rate from 5 – 80 hours a month to patch bugs, support launches, and cover for missing headcount while your internal HR finds right the permanent hires."
        />
        <HowWeWorkItem
          fadeDuration={1100}
          iconPath={icon5}
          title="Project Management"
          description="Have the team but need to focus your leadership elsewhere? A lead engineer from OPNA can work with your team to keep your deliverables on track and improve the product."
        />
      </div>
    </div>
  </section>
);

export default HowWeWork;
