import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../../data/urls';

const Softskills = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "people"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section className="seo_features_one sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="seo_features_img seo_features_img_two h-100">
              <div className="round_circle" />
              <div className="round_circle two" />
              <Img
                fluid={image}
                className="h-100"
                imgStyle={{
                  margin: '0 auto',
                  width: '75%',
                  height: 'auto',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <Fade bottom cascade>
              <div className="seo_features_content">
                <h2 className="wow fadeInUp">Soft Skills &amp; Communication</h2>
                <h6 className="wow fadeInUp">We Got ‘Em</h6>
                <p className="wow fadeInUp">Rarely do the desires of clients and developers overlap and it’s no secret that programmers tend to speak in code – in more ways than one. We’re very aware of this dynamic and do all that we can to eliminate it. A truly successful product requires open and honest communication at all levels. All our developers have past experience in socially demanding and public facing roles.</p>
                <Link to={urls.contactUs} className="seo_btn seo_btn_one btn_hover wow fadeInUp">Get a quote</Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Softskills;
